import React, { useState } from 'react';
import {
  ChevronDown,
  Trophy,
  Users,
  History,
  Star,
  Menu,
  X,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Phone,
  Mail,
  MapPin,
  Link
} from 'lucide-react';
import { motion } from 'framer-motion';
import Heder from './Top';
import Footer from './Bottum';
import QuizGame from './QuizGame';
import FeaturedSection from './FeaturedSection';
import CricketNewsSection from './CricketNewsSection';


const Home = () => {
  const [activeSection, setActiveSection] = useState('history');

  const featuredPlayers = [
    { name: 'Sir Donald Bradman', country: 'Australia', achievement: 'Highest Test batting average of 99.94' },
    { name: 'Sachin Tendulkar', country: 'India', achievement: '100 international centuries' },
    { name: 'Shane Warne', country: 'Australia', achievement: '708 Test wickets' },

  ];



  const content = {
    history: {
      title: 'Cricket History',
      Link:'./More',
      text: 'Cricket has evolved from its origins in England during the 16th century to become one of the world\'s most popular sports. The first Test match was played in 1877 between Australia and England.',
    },
    teams: {
      title: 'Famous Teams',
      Link:'./More',
      text: 'From the dominant West Indies of the 1970s to the Australian team of the late 1990s and early 2000s, cricket has seen many legendary teams.',
    },
    moments: {
      title: 'Historic Moments',
      Link:'./More',
      text: 'From the 1932-33 Bodyline series to India\'s triumph in the 1983 World Cup, cricket has provided countless memorable moments.',
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-100">
      <Heder />

      <div className="relative h-96 bg-gradient-to-r from-green-600 to-green-800">
        <div className="absolute inset-0 bg-black opacity-50" />
        <motion.div
          className="relative flex flex-col items-center justify-center h-full text-white px-4"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-bold mb-4">Cricket Legacy</h1>
          <p className="text-xl text-center max-w-2xl">
            Discover the rich history and legendary moments of cricket
          </p>
          <button
            onClick={() => {
              const element = document.getElementById('features');
              element?.scrollIntoView({ behavior: 'smooth' });
            }}
            className="mt-8 bg-white text-green-800 px-6 py-3 rounded-full font-semibold 
                      flex items-center gap-2 transform transition-transform duration-200 hover:scale-105"
          >
            Explore More
            <ChevronDown className="animate-bounce" />
          </button>
        </motion.div>
      </div>

      <FeaturedSection />


      <motion.div
        id="features"
        className="max-w-6xl mx-auto py-16 px-4"
        initial="hidden"
        whileInView="visible"
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0, transition: { duration: 1 } },
        }}
        viewport={{ once: true }}
      >
        <div className="grid md:grid-cols-3 gap-8">
          {['Rich History', 'Legendary Teams', 'Iconic Moments'].map((feature, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg"
              variants={cardVariants}
              whileHover={{ rotateY: 10, rotateX: 5 }}
              style={{ perspective: '1000px' }}
            >
              {index === 0 && <History className="w-12 h-12 text-green-600 mb-4" />}
              {index === 1 && <Trophy className="w-12 h-12 text-green-600 mb-4" />}
              {index === 2 && <Star className="w-12 h-12 text-green-600 mb-4" />}
              <h3 className="text-xl font-semibold mb-2">{feature}</h3>
              <p className="text-gray-600">Explore cricket&apos;s {feature.toLowerCase()}.</p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      <div className="bg-white py-16 px-4">
        <motion.div
          className="max-w-6xl mx-auto"
          initial="hidden"
          whileInView="visible"
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
          }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl font-bold text-center mb-12">Featured Players</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {featuredPlayers.map((player, index) => (
              <motion.div
                key={index}
                className="bg-green-50 p-6 rounded-lg shadow-md"
                whileHover={{ rotateY: 15, scale: 1.05 }}
                style={{ perspective: '1000px' }}
              >
                <div className="flex items-center gap-4 mb-4">
                  <Users className="w-8 h-8 text-green-600" />
                  <h3 className="text-xl font-semibold">{player.name}</h3>
                </div>
                <p className="text-gray-600 mb-2">{player.country}</p>
                <p className="text-sm text-gray-500">{player.achievement}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>

      <div className="max-w-6xl mx-auto py-16 px-4">
        <div className="flex gap-4 mb-8 overflow-x-auto">
          {Object.keys(content).map((key) => (
            <button
              key={key}
              onClick={() => setActiveSection(key)}
              className={`px-6 py-2 rounded-full font-semibold transition-colors duration-200
                ${activeSection === key
                  ? 'bg-green-600 text-white'
                  : 'bg-white text-green-600 hover:bg-green-50'}`}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </button>
          ))}
        </div>
        <motion.div
          className="bg-white p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-2xl font-bold mb-4">{content[activeSection].title}</h2>
          <p className="text-gray-600">{content[activeSection].text}</p>
        </motion.div>
      </div>


      
            <CricketNewsSection/>





      <div className="bg-white py-16 px-4">
        <motion.div
          className="max-w-6xl mx-auto"
          initial="hidden"
          whileInView="visible"
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
          }}
          viewport={{ once: true }}
        >

        </motion.div>

        <motion.div
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          whileHover={{ scale: 1.05 }}
        >


        </motion.div>




      </div>

    

      <QuizGame />


      <Footer />
    </div>
  );
};

export default Home;
