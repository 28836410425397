import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

function FeaturedSection() {
    return (
        <section className="mt-16 px-4 sm:px-6 lg:px-12 xl:px-16 bg-white rounded-lg shadow-lg py-12">
            <motion.h3
                className="text-3xl font-semibold text-center mb-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                Featured Article: Cricket's Evolution
            </motion.h3>
            <motion.p
                className="text-lg text-gray-700 text-center max-w-3xl mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                Cricket has evolved tremendously over the years, from traditional Test matches to the fast-paced T20 formats. This article takes you through the fascinating journey of cricket's transformation over the decades, including iconic moments and legendary players who shaped the game.
            </motion.p>

            {/* Swiper Slider */}
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                spaceBetween={20}
                slidesPerView={1}
                className="mt-8"
            >
                <SwiperSlide>
                    <img
                        src="images/dgg.jpg"
                        alt="Cricket Evolution Image 1"
                        className="rounded-lg w-full"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="images/ttu.jpg"
                        alt="Cricket Evolution Image 2"
                        className="rounded-lg w-full"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="images/fvv.jpg"
                        alt="Cricket Evolution Image 3"
                        className="rounded-lg w-full"
                    />
                </SwiperSlide>
            </Swiper>

            <motion.a
                href="#"
                className="block text-center text-blue-600 hover:underline mt-6"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                Read Full Article
            </motion.a>
        </section>
    );
}

export default FeaturedSection;
