import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; 

const cricketNews = [
  {
    title: "India Wins the World Cup!",
    description: "In an epic match, India clinched the title with a thrilling last-over finish.",
    image: "images/winn.png",
    link: "/world-cup-win", 
  },
  {
    title: "Virat Kohli Hits Century!",
    description: "Kohli once again shows why he's the king of modern cricket.",
    image: "images/vk.jpg",
    link: "/kohli-century", 
  },
  {
    title: "Bumrah's Hat-trick!",
    description: "Jasprit Bumrah creates history with a spectacular hat-trick.",
    image: "images/bm.jpg",
    link: "/bumrah-hat-trick",
  },
];

const CricketNewsSection = () => {
  return (
    <section className="mt-12 px-4 sm:px-6 lg:px-12 xl:px-16" id="teams-section">
      <h2 className="text-3xl font-semibold text-center mb-8">Latest Cricket News</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cricketNews.map((Players, index) => (
          <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            whileHover={{ scale: 1.05 }}
          >
            <motion.img
              src={Players.image}
              alt={Players.title}
              className="w-full h-48 object-cover mb-4 rounded-lg"
              whileHover={{ scale: 1.1 }}
            />
            <motion.h3
              className="text-2xl font-semibold mb-3"
              whileHover={{ color: "#4CAF50" }}
            >
              {Players.title}
            </motion.h3>
            <motion.p
              className="text-gray-700 mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              {Players.description}
            </motion.p>
            <motion.div whileHover={{ scale: 1.1 }}>
              <Link
                to={Players.link}
                className="text-blue-600 hover:underline"
              >
                Read More
              </Link>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default CricketNewsSection;
