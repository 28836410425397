import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Navbar } from "flowbite-react";
import { motion } from "framer-motion";

const Heder = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header bg-gradient-to-r from-green-600 to-green-800 w-full py-4 relative">
      <div className="container mx-auto px-4">
        <Navbar className="bg-transparent flex items-center justify-between">
          <motion.div
            className="brand"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <Navbar.Brand href="#">
              <div className="box-border w-full md:w-auto">
                <motion.p
                  whileHover={{ scale: 1.1, rotate: 2 }}
                  whileTap={{ scale: 0.9, rotate: -2 }}
                  className="text-white text-xl md:text-2xl font-bold"
                >
                  Cricket <span className="text-black">Legacy</span>
                </motion.p>
              </div>
            </Navbar.Brand>
          </motion.div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white focus:outline-none"
              aria-label="Toggle Navigation"
            >
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                whileHover={{ scale: 1.2 }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </motion.svg>
            </button>
          </div>

          <Navbar.Collapse className="hidden md:flex">
            <motion.nav
              className="flex space-x-4"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <NavLink to="/" label="Home" />
              <NavLink to="/News" label="News" />
              <NavLink to="/Teams" label="Teams" />
              <NavLink to="/Players" label="Players" />
              <NavLink to="/Contact" label="Contact Us" />
            </motion.nav>
          </Navbar.Collapse>
        </Navbar>

        {isOpen && (
          <motion.div
            className="md:hidden mt-4 bg-green-700 rounded-lg shadow-lg"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <nav className="flex flex-col space-y-2 p-4">
              <NavLink to="/" label="Home" />
              <NavLink to="/News" label="News" />
              <NavLink to="/Teams" label="Teams" />
              <NavLink to="/Players" label="Players" />
              <NavLink to="/Contact" label="Contact Us" />
            </nav>
          </motion.div>
        )}
      </div>
    </div>
  );
};

const NavLink = ({ to, label }) => (
  <motion.div
    whileHover={{
      scale: 1.1,
      translateY: -2,
      boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)",
    }}
    transition={{ type: "spring", stiffness: 200 }}
  >
    <Link
      to={to}
      className="text-white transition duration-300 font-medium hover:text-black hover:underline underline-offset-4 decoration-2 decoration-green-400"
    >
      {label}
    </Link>
  </motion.div>
);

export default Heder;
