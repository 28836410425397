import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const QuizGame = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const [isQuizOver, setIsQuizOver] = useState(false);

  const questions = [
    {
      question: 'Which bowler has the most Test wickets?',
      options: ['Muttiah Muralitharan', 'Shane Warne', 'James Anderson', 'Anil Kumble'],
      correct: 0,
    },
    {
      question: 'Who won the IPL 2023?',
      options: ['Chennai Super Kings', 'Mumbai Indians', 'Gujarat Titans', 'Rajasthan Royals'],
      correct: 0,
    },
    {
      question: 'Who holds the record for the fastest fifty in T20Is?',
      options: ['KL Rahul', 'Yuvraj Singh', 'Chris Gayle', 'David Miller'],
      correct: 1,
    },
  ];

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      handleTimeout();
    }
  }, [timeLeft]);

  const handleOptionClick = (index) => {
    if (index === questions[currentQuestionIndex].correct) {
      setScore(score + 1);
    }
    goToNextQuestion();
  };

  const handleTimeout = () => {
    goToNextQuestion();
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeLeft(15);
    } else {
      setIsQuizOver(true);
    }
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setTimeLeft(15);
    setIsQuizOver(false);
  };

  const questionVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  const buttonHoverEffect = {
    whileHover: {
      scale: 1.1,
      rotateX: 5,
      rotateY: 5,
      transition: { duration: 0.3 },
    },
  };

  const quizCardEffect = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className="bg-gray-100 py-12 px-4">
      <motion.div
        className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg"
        initial="hidden"
        animate="visible"
        variants={quizCardEffect}
      >
        {isQuizOver ? (
          <motion.div
            className="text-center"
            initial="hidden"
            animate="visible"
            variants={questionVariants}
          >
            <h2 className="text-2xl font-bold mb-4">Quiz Over!</h2>
            <p className="text-lg mb-6">Your Score: {score} / {questions.length}</p>
            <motion.button
              onClick={resetQuiz}
              className="bg-green-600 text-white px-6 py-3 rounded-full font-semibold transition-colors duration-200 hover:bg-green-800"
              {...buttonHoverEffect}
            >
              Play Again
            </motion.button>
          </motion.div>
        ) : (
          <>
            <motion.div
              className="flex justify-between items-center mb-6"
              initial="hidden"
              animate="visible"
              variants={questionVariants}
            >
              <h2 className="text-xl font-bold">
                Question {currentQuestionIndex + 1} of {questions.length}
              </h2>
              <span className="text-lg font-semibold text-red-600">Time Left: {timeLeft}s</span>
            </motion.div>
            <motion.h3
              className="text-2xl font-semibold mb-6"
              initial="hidden"
              animate="visible"
              variants={questionVariants}
            >
              {questions[currentQuestionIndex].question}
            </motion.h3>
            <div className="grid gap-4">
              {questions[currentQuestionIndex].options.map((option, index) => (
                <motion.button
                  key={index}
                  onClick={() => handleOptionClick(index)}
                  className="w-full bg-green-50 text-green-800 px-4 py-2 rounded-md hover:bg-green-100 transition-colors duration-200"
                  whileHover={{ scale: 1.05, rotateY: 5 }}
                  style={{ perspective: '1000px' }}
                >
                  {option}
                </motion.button>
              ))}
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default QuizGame;
