import React from "react";
import { Phone, Mail, MapPin, Facebook, Twitter, Instagram, Youtube } from 'lucide-react';


const Footer = () => {
    return (
      <footer className="bg-green-800 text-white">
        <div className="max-w-6xl mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            
            <div>
              <h3 className="text-xl font-bold mb-4">About Cricket Legacy</h3>
              <p className="text-gray-300">
                Dedicated to preserving and sharing the rich history and culture of cricket around the world.
              </p>
            </div>
  
            
            <div>
              <h3 className="text-xl font-bold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                {['About Us', 'Privacy Policy', 'Terms of Service', 'FAQ'].map((item) => (
                  <li key={item}>
                    <a href="#" className="text-gray-300 hover:text-white transition-colors duration-200">
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
  
            
            <div>
              <h3 className="text-xl font-bold mb-4">Contact Us</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <Phone className="h-4 w-4" />
                  <span className="text-gray-300">+91 9875064586</span>
                </li>
                <li className="flex items-center gap-2">
                  <Mail className="h-4 w-4" />
                  <span className="text-gray-300">info@cricketlegacy.com</span>
                </li>
                <li className="flex items-center gap-2">
                  <MapPin className="h-4 w-4" />
                  <span className="text-gray-300">402 Sunriseinfo, Sports City</span>
                </li>
              </ul>
            </div>
  
          
            <div>
              <h3 className="text-xl font-bold mb-4">Follow Us</h3>
              <div className="flex space-x-4">
                {[
                  { Icon: Facebook, label: 'Facebook' },
                  { Icon: Twitter, label: 'Twitter' },
                  { Icon: Instagram, label: 'Instagram' },
                  { Icon: Youtube, label: 'Youtube' }
                ].map(({ Icon, label }) => (
                  <a
                    key={label}
                    href="#"
                    className="hover:text-green-400 transition-colors duration-200"
                    aria-label={label}
                  >
                    <Icon className="h-6 w-6" />
                  </a>
                ))}
              </div>
            </div>
          </div>
  
         
          <div className="border-t border-green-700 mt-8 pt-8 text-center">
            <p className="text-gray-300">
              © {new Date().getFullYear()} Cricket Legacy. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  };

  export default Footer;