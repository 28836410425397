import React, { useState } from "react";
import Heder from "./Top";
import Footer from "./Bottum";
import { motion } from "framer-motion";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        gender: "",
        subscription: false,
        topics: [],
        department: "",
        date: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleMultiSelect = (e) => {
        const options = Array.from(e.target.selectedOptions, (option) => option.value);
        setFormData({ ...formData, topics: options });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Form submitted successfully!\nData: ${JSON.stringify(formData, null, 2)}`);
        setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            gender: "",
            subscription: false,
            topics: [],
            department: "",
            date: "",
        });
    };

    return (
        <>
            <Heder />

            <main className="bg-gradient-to-b from-green-50 to-green-100 py-16">
                <section className="text-center py-12 bg-green-700 text-white">
                    <motion.h1
                        className="text-4xl font-bold"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        Contact Us
                    </motion.h1>
                    <motion.p
                        className="text-lg mt-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.5 }}
                    >
                        Fill out the form below for inquiries, feedback, or support.
                    </motion.p>
                </section>

                
                <section className="mt-12 px-4 sm:px-6 lg:px-12 xl:px-16">
                    <motion.div
                        className="max-w-6xl mx-auto bg-white p-10 rounded-3xl shadow-xl"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-8">
                              
                                <div>
                                    <label htmlFor="name" className="block text-lg font-medium text-gray-800 mb-2">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full px-4 py-3 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
                                        placeholder="Enter your full name"
                                        required
                                    />
                                </div>

                                
                                <div>
                                    <label htmlFor="email" className="block text-lg font-medium text-gray-800 mb-2">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full px-4 py-3 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>

                                
                                <div>
                                    <label htmlFor="phone" className="block text-lg font-medium text-gray-800 mb-2">
                                        Phone Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className="w-full px-4 py-3 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
                                        placeholder="Enter your phone number"
                                    />
                                </div>

                                
                                <div>
                                    <label htmlFor="gender" className="block text-lg font-medium text-gray-800 mb-2">
                                        Gender
                                    </label>
                                    <div className="flex gap-4">
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="gender"
                                                value="male"
                                                checked={formData.gender === "male"}
                                                onChange={handleChange}
                                                className="mr-2"
                                            />
                                            Male
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="gender"
                                                value="female"
                                                checked={formData.gender === "female"}
                                                onChange={handleChange}
                                                className="mr-2"
                                            />
                                            Female
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="gender"
                                                value="other"
                                                checked={formData.gender === "other"}
                                                onChange={handleChange}
                                                className="mr-2"
                                            />
                                            Other
                                        </label>
                                    </div>
                                </div>
                            </div>

                           
                            <div className="mb-8">
                                <label htmlFor="subject" className="block text-lg font-medium text-gray-800 mb-2">
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    className="w-full px-4 py-3 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
                                    placeholder="Subject of your inquiry"
                                    required
                                />
                            </div>

                           
                            <div className="mb-8">
                                <label htmlFor="topics" className="block text-lg font-medium text-gray-800 mb-2">
                                    Topics of Interest
                                </label>
                                <select
                                    id="topics"
                                    name="topics"
                                    multiple
                                    value={formData.topics}
                                    onChange={handleMultiSelect}
                                    className="w-full px-4 py-3 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
                                >
                                    <option value="news">News Updates</option>
                                    <option value="events">Events</option>
                                    <option value="support">Customer Support</option>
                                    <option value="feedback">Feedback</option>
                                </select>
                                <p className="text-sm text-gray-600 mt-2">
                                    Hold down the <strong>Ctrl</strong> (Windows) or <strong>Command</strong> (Mac) key to select multiple options.
                                </p>
                            </div>

                          
                            <div className="mb-8">
                                <label htmlFor="message" className="block text-lg font-medium text-gray-800 mb-2">
                                    Your Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows="6"
                                    className="w-full px-4 py-3 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
                                    placeholder="How can we assist you?"
                                    required
                                />
                            </div>

                           
                            <div className="mb-8">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="subscription"
                                        checked={formData.subscription}
                                        onChange={handleChange}
                                        className="mr-2"
                                    />
                                    Subscribe to our newsletter
                                </label>
                            </div>

                            <motion.button
                                type="submit"
                                className="mt-6 w-full bg-green-600 text-white py-4 px-6 rounded-full font-semibold hover:bg-green-700 transition duration-300 ease-in-out"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                Submit Form
                            </motion.button>
                        </form>
                    </motion.div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default Contact;
