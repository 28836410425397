import React from 'react';
import { motion } from 'framer-motion'; 
import Heder from './Top';
import Footer from './Bottum';

const News = () => {
    const teams = [
        {
            title: "The West Indies - Dominating the 1970s",
            description: "A look back at the West Indies' dominance in the 1970s, where they went undefeated for over 15 years...",
            legends: ["Sir Vivian Richards", "Clive Lloyd", "Joel Garner"],
            image: "images/wi.jpg",
        },
        {
            title: "Australia’s Golden Era (1990s-2000s)",
            description: "Australia’s cricket team from the 1990s to the early 2000s is considered by many as the greatest team in history...",
            legends: ["Shane Warne", "Ricky Ponting", "Glenn McGrath"],
            image: "images/ast.jpg",
        },
        {
            title: "India's 2000s Revival",
            description: "India's cricket team went through a resurgence in the 2000s, with players like Sourav Ganguly, Rahul Dravid, and Virender Sehwag leading the way...",
            legends: ["Sourav Ganguly", "Rahul Dravid", "Virender Sehwag"],
            image: "images/ifk.jpg",
        },
        {
            title: "Pakistan's 1992 World Cup Win",
            description: "Pakistan's victory in the 1992 World Cup under the captaincy of Imran Khan is one of the most iconic moments in cricket history...",
            legends: ["Imran Khan", "Wasim Akram", "Javed Miandad"],
            image: "images/pol.jpg",
        },
        {
            title: "South Africa - A Team of Resilience",
            description: "South Africa has had a turbulent yet highly competitive cricket history, known for their strong performances in World Cups...",
            legends: ["Jacques Kallis", "AB de Villiers", "Graeme Smith"],
            image: "images/sou.jpg",
        },
        {
            title: "New Zealand - The Underdogs",
            description: "Known for their fighting spirit, New Zealand has consistently punched above their weight in international cricket...",
            legends: ["Brendon McCullum", "Daniel Vettori", "Martin Crowe"],
            image: "images/nz.jpg",
        },
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-100">
            
            <div className="pb-6">
                <Heder />
            </div>

            
            <motion.div 
                className="relative h-96 bg-gradient-to-r from-green-600 to-green-800"
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black opacity-50" />
                <div className="relative flex flex-col items-center justify-center h-full text-white px-4">
                    <motion.h1 
                        className="text-5xl font-bold mb-4" 
                        initial={{ y: -30, opacity: 0 }} 
                        animate={{ y: 0, opacity: 1 }} 
                        transition={{ duration: 0.8 }}
                    >
                        Teams Page
                    </motion.h1>
                    <motion.p 
                        className="text-xl text-center max-w-2xl" 
                        initial={{ opacity: 0 }} 
                        animate={{ opacity: 1 }} 
                        transition={{ duration: 1 }}
                    >
                        Discover the rich history and legendary moments of cricket
                    </motion.p>
                    <motion.button
                        onClick={() => {
                            const element = document.getElementById('teams-section');
                            element?.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="mt-8 bg-white text-green-800 px-6 py-3 rounded-full font-semibold flex items-center gap-2"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        Explore More
                        <span className="animate-bounce">&#x25BC;</span>
                    </motion.button>
                </div>
            </motion.div>

            
            <div id="teams-section" className="pt-16 pb-20 px-6 lg:px-12">
                <div className="max-w-6xl mx-auto">
                    <motion.h2 
                        className="text-4xl font-bold text-center mb-12"
                        initial={{ x: -30, opacity: 0 }} 
                        animate={{ x: 0, opacity: 1 }} 
                        transition={{ duration: 1 }}
                    >
                        Legendary Cricket Teams
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {teams.map((team, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-6 rounded-lg shadow-md hover:shadow-2xl transform transition-all duration-700 hover:scale-105 hover:-translate-y-2"
                                initial={{ opacity: 0, y: 30 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.2, duration: 0.8 }}
                            >
                                <motion.img
                                    src={team.image}
                                    alt={team.title}
                                    className="w-full h-48 object-cover rounded-md mb-4"
                                    whileHover={{ scale: 1.05 }}
                                />
                                <h3 className="text-2xl font-semibold mb-2">{team.title}</h3>
                                <p className="text-gray-600">{team.description}</p>
                                <h4 className="text-xl font-semibold mt-4">Team Legends:</h4>
                                <ul className="list-disc pl-6">
                                    {team.legends.map((legend, i) => (
                                        <li key={i}>{legend}</li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>

            
            <div className="bg-green-800 text-white py-8 px-6">
                <Footer />
            </div>
        </div>
    );
};

export default News;
