import React from 'react';
import { motion } from 'framer-motion';
import Heder from './Top';
import Footer from './Bottum';



const News = () => {
    const cricketNews = [
        {
            title: 'India Clinches T20 Series Against Australia',
            description: 'India wins the T20 series 3-2 against Australia, with Suryakumar Yadav hitting a brilliant century in the decider.',
            link: '#',
            image: 'images/kklo.jpg',
        },
        {
            title: 'Shubman Gill Reaches New Heights',
            description: 'Shubman Gill’s double century in ODIs against South Africa has solidified his place as one of the rising stars of world cricket.',
            link: '#',
            image: 'images/sbg.jpg',
        },
        {
            title: 'ICC Announces Women’s Premier League',
            description: 'The ICC has unveiled plans for the Women’s Premier League, aiming to provide equal opportunities and visibility to women cricketers globally.',
            link: '#',
            image: 'images/jhkh.jpeg',
        },
    ];

    return (
        <>
            <div className="pb-6">
                <Heder />
            </div>

            <motion.div 
                className="relative h-96 bg-gradient-to-r from-green-600 to-green-800"
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black opacity-50" />
                <div className="relative flex flex-col items-center justify-center h-full text-white px-4">
                    <motion.h1 
                        className="text-5xl font-bold mb-4" 
                        initial={{ y: -30, opacity: 0 }} 
                        animate={{ y: 0, opacity: 1 }} 
                        transition={{ duration: 0.8 }}
                        whileHover={{ scale: 1.1 }}
                    >
                        Cricket Headlines
                    </motion.h1>
                    <motion.p 
                        className="text-xl text-center max-w-2xl mx-auto mb-8" 
                        initial={{ opacity: 0 }} 
                        animate={{ opacity: 1 }} 
                        transition={{ duration: 1 }}
                    >
                        Stay updated with the most recent cricket news from around the world.
                    </motion.p>
                    <motion.button
                        onClick={() => {
                            const element = document.getElementById('teams-section');
                            element?.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="mt-8 bg-white text-blue-800 px-6 py-3 rounded-full font-semibold flex items-center gap-2"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        Discover More
                        <span className="animate-bounce">&#x25BC;</span>
                    </motion.button>
                </div>
            </motion.div>

            <main className="bg-gradient-to-b from-blue-50 to-blue-100 py-16">
                <section className="mt-12 px-4 sm:px-6 lg:px-12 xl:px-16" id="teams-section">
                    <h2 className="text-3xl font-semibold text-center mb-8">Latest Cricket News</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {cricketNews.map((item, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300"
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8, delay: index * 0.2 }}
                                whileHover={{ scale: 1.05 }}
                            >
                                <motion.img
                                    src={item.image}
                                    alt={item.title}
                                    className="w-full h-48 object-cover mb-4 rounded-lg"
                                    whileHover={{ scale: 1.1 }}
                                />
                                <motion.h3
                                    className="text-2xl font-semibold mb-3"
                                    whileHover={{ color: "#4CAF50" }}
                                >
                                    {item.title}
                                </motion.h3>
                                <motion.p
                                    className="text-gray-700 mb-4"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.6 }}
                                >
                                    {item.description}
                                </motion.p>
                                <motion.a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:underline"
                                    whileHover={{ scale: 1.1, color: "#0077cc" }}
                                >
                                    Read More
                                </motion.a>
                            </motion.div>
                        ))}
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default News;
