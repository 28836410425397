import React from "react";
import { motion } from "framer-motion";
import Heder from './Top';
import Footer from './Bottum';

const Players = () => {
    const players = [
        {
            name: "Sachin Tendulkar",
            country: "India",
            role: "Batsman",
            stats: "Runs: 34,357 | Centuries: 100 | Matches: 664",
            description: "Known as the 'God of Cricket,' Sachin Tendulkar is regarded as one of the greatest batsmen in cricket history.",
            image: "images/sh.jpeg",
        },
        {
            name: "Muttiah Muralitharan",
            country: "Sri Lanka",
            role: "Bowler",
            stats: "Wickets: 1,347 | Matches: 495 | Best Bowling: 9/51",
            description: "Muralitharan is the highest wicket-taker in the history of Test and ODI cricket.",
            image: "images/mai.jpg",
            
        },
        {
            name: "Virat Kohli",
            country: "India",
            role: "Batsman",
            stats: "Runs: 25,482 | Centuries: 76 | Matches: 500+",
            description: "Known for his aggressive batting style, Virat Kohli is considered a modern-day cricket legend.",
            image: "images/hyt.jpg",
           
        },
        {
            name: "Jacques Kallis",
            country: "South Africa",
            role: "All-Rounder",
            stats: "Runs: 25,534 | Wickets: 577 | Matches: 519",
            description: "One of the best all-rounders in cricket history, known for his consistency with both bat and ball.",
            image: "images/jq.jpg",
            
        },
        {
            name: "Shane Warne",
            country: "Australia",
            role: "Bowler",
            stats: "Wickets: 1,001 | Matches: 339 | Best Bowling: 8/71",
            description: "A magician with the ball, Shane Warne was one of the greatest leg-spinners of all time.",
            image: "images/sgt.jpg",
            
        },
        {
            name: "Brian Lara",
            country: "West Indies",
            role: "Batsman",
            stats: "Runs: 22,358 | High Score: 400* | Matches: 430",
            description: "Known for his flamboyant style and record-breaking innings, Lara is one of the finest batsmen in cricket history.",
            image: "images/lr.jpg",
            
        },
        {
            name: "MS Dhoni",
            country: "India",
            role: "Wicketkeeper-Batsman",
            stats: "Runs: 17,266 | Catches: 634 | Matches: 538",
            description: "India's most successful captain, known for his calm demeanor and exceptional finishing skills.",
            image: "images/msd.jpg",

        },
        {
            name: "Sir Vivian Richards",
            country: "West Indies",
            role: "Batsman",
            stats: "Runs: 15,261 | Centuries: 35 | Matches: 308",
            description: "Known for his aggressive batting and unmatched confidence, Richards dominated bowlers in his era.",
            image: "images/avc.jpg",
            
        },
        {
            name: "Imran Khan",
            country: "Pakistan",
            role: "All-Rounder",
            stats: "Runs: 7,516 | Wickets: 544 | Matches: 263",
            description: "The charismatic leader who led Pakistan to their first-ever World Cup title in 1992.",
            image: "images/imk.jpg",
        },
        {
            name: "Ricky Ponting",
            country: "Australia",
            role: "Batsman",
            stats: "Runs: 27,483 | Centuries: 71 | Matches: 560",
            description: "One of cricket's most successful captains, Ponting led Australia through a golden era.",
            image: "images/rky.jpeg",
        },
        {
            name: "Wasim Akram",
            country: "Pakistan",
            role: "Bowler",
            stats: "Wickets: 916 | Matches: 460 | Best Bowling: 7/36",
            description: "The 'Sultan of Swing,' Wasim Akram is regarded as one of the greatest fast bowlers in cricket history.",
            image: "images/wsm.jpg",
        },
        {
            name: "AB de Villiers",
            country: "South Africa",
            role: "Batsman",
            stats: "Runs: 20,014 | Centuries: 47 | Matches: 420",
            description: "Known for his 360-degree batting and innovation, AB is one of the most entertaining players ever.",
            image: "images/abde.jpg",
        },
    ];
    

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.2, duration: 0.5 },
        }),
        hover: {
            scale: 1.1,
            rotateY: 10,
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)",
            transition: { duration: 0.5 },
        },
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-100">
            <Heder />

            <motion.div
                className="relative h-96 bg-gradient-to-r from-green-600 to-green-800"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black opacity-50" />
                <motion.div
                    className="relative flex flex-col items-center justify-center h-full text-white px-4"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <h1 className="text-5xl font-bold mb-4">Players Page</h1>
                    <p className="text-xl text-center max-w-2xl">
                        Explore the stories and stats of cricket's all-time greats.
                    </p>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => {
                            const element = document.getElementById('players-section');
                            element?.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="mt-8 bg-white text-green-800 px-6 py-3 rounded-full font-semibold 
                                   flex items-center gap-2"
                    >
                        Explore More
                        <span className="animate-bounce">&#x25BC;</span>
                    </motion.button>
                </motion.div>
            </motion.div>

            <div id="players-section" className="pt-16 pb-20 px-6 lg:px-12">
                <div className="max-w-6xl mx-auto">
                    <motion.h2
                        className="text-4xl font-bold text-center mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                    >
                        Legendary Cricket Players
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {players.map((player, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-6 rounded-lg shadow-md transform transition-transform duration-700"
                                variants={cardVariants}
                                initial="hidden"
                                animate="visible"
                                whileHover="hover"
                                custom={index}
                            >
                                <img
                                    src={player.image}
                                    alt={player.name}
                                    className="w-full h-48 object-cover rounded-md mb-4"
                                />
                                <h3 className="text-2xl font-semibold mb-2">{player.name}</h3>
                                <p className="text-gray-600 mb-2"><strong>Country:</strong> {player.country}</p>
                                <p className="text-gray-600 mb-2"><strong>Role:</strong> {player.role}</p>
                                <p className="text-gray-600 mb-2"><strong>Stats:</strong> {player.stats}</p>
                                <p className="text-gray-700">{player.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Players;

