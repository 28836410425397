import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import News from './News';
import Teams from './Teams';
import Players from './Players';
import CricketNewsSection from './CricketNewsSection';
import Heder from './Top';
import Footer from './Bottum';


const WorldCupWin = () => (
  <>
    <Heder />
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-6 text-center">
        India Wins the ICC Cricket World Cup!
      </h1>
      <img
        src="images/winn.png"
        alt="India Wins World Cup"
        className="w-full h-auto mb-6 rounded-lg shadow-lg"
      />
      <p className="text-lg text-gray-700 leading-relaxed">
        In an unforgettable final, India clinched the ICC Cricket World Cup with a
        thrilling last-over finish. The match, held at the iconic Narendra Modi
        Stadium in Ahmedabad, was a spectacle of nerves, skill, and determination.
        Fans packed the stands, their cheers echoing around the stadium, as the
        Indian team rose to the challenge against a formidable opponent.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        The game was a rollercoaster from start to finish. Batting first, the
        opposition set a challenging target of 305 runs. India’s chase began
        shakily, with early wickets testing the team’s resolve. However, a
        brilliant partnership between skipper Virat Kohli and KL Rahul steadied the
        innings. Kohli’s masterclass of 92 runs off 85 balls and Rahul’s composed
        78 proved to be the backbone of India’s chase.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        As the game entered its final over, India needed 10 runs to win with three
        wickets in hand. Ravindra Jadeja, known for his all-round brilliance, hit a
        crucial boundary before Jasprit Bumrah sealed the victory with a lofted
        shot over mid-off, sparking wild celebrations across the country. Fireworks
        lit up the sky, and fans danced in the streets, celebrating the historic
        moment.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        This win marked India’s third ICC Cricket World Cup victory, following
        their triumphs in 1983 and 2011. It highlighted the team’s exceptional
        depth, from Rohit Sharma’s consistency at the top order to the deadly
        precision of Bumrah’s yorkers. The victory also cemented Rahul Dravid’s
        legacy as one of India’s finest cricket coaches, whose strategies and
        mentorship guided the team to glory.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        The win is not just a cricketing milestone but a moment of unity for the
        nation. Social media erupted with messages of congratulations from fans and
        celebrities alike. Prime Minister Narendra Modi praised the team, calling
        it a "golden chapter in Indian sports history." This World Cup win will
        undoubtedly inspire generations of young cricketers to dream big and aim
        for excellence on the global stage.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        As the celebrations continue, the Indian team has etched its name in the
        annals of cricketing history, reminding the world that their passion,
        resilience, and teamwork are truly unmatched. The journey to this victory
        has been nothing short of extraordinary, and it will be cherished for years
        to come.
      </p>
    </div>
    <Footer />

  </>
);

const KohliCentury = () => (
  <>
    <Heder />
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-6 text-center">Virat Kohli Hits Century!</h1>
      <img
        src="images/vk.jpg"
        alt="Virat Kohli Century"
        className="w-full h-auto mb-6 rounded-lg shadow-lg"
      />
      <p className="text-lg text-gray-700 leading-relaxed">
        In a display of pure brilliance, Virat Kohli scored his 75th international century, cementing his legacy as one of the greatest cricketers of all time. Playing against a tough opponent in a high-stakes match, Kohli's precision, resilience, and unmatched technique dazzled cricket fans worldwide. Facing a formidable bowling attack, he stood like a rock, delivering a masterclass in timing and shot selection.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        Kohli reached his century in just 115 balls, with 12 boundaries and 2 sixes, showcasing his ability to anchor the innings while maintaining a healthy strike rate. His celebration upon reaching the milestone reflected his passion and commitment to the game, as he raised his bat and looked to the skies, acknowledging the support of fans and teammates.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        This remarkable feat not only helped India secure a commanding victory in the series but also set new benchmarks in cricket history. Kohli's century came during a crucial phase of the match, helping India recover from early setbacks and setting a formidable target of 320 runs. The innings also marked Kohli’s 25th ODI century on home soil, a record that underscores his dominance in familiar conditions.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        Speaking at the post-match presentation, Kohli said, "It’s always a special feeling to contribute to the team's success. I dedicate this century to my family and the fans who never stop believing in me. Records are meant to be broken, but playing for India remains my greatest honor."
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        Cricketing legends and fans alike took to social media to applaud Kohli’s incredible achievement. Sachin Tendulkar tweeted, "Congratulations, Virat! 75 international centuries is a phenomenal feat. The way you’ve carried Indian cricket forward is inspiring." Similarly, former captain MS Dhoni sent a message, calling Kohli "a true fighter and a game-changer."
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        This century comes at a crucial time for Kohli as he continues to silence critics and demonstrate why he is regarded as one of the modern greats. With this knock, Kohli inches closer to Sachin Tendulkar’s all-time record of 100 international centuries, a milestone many believe he can achieve in the coming years.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        As the cricketing world celebrates this milestone, Kohli’s century serves as a reminder of his unparalleled talent, relentless work ethic, and enduring passion for the game. Fans eagerly await what’s next for this cricketing maestro as he continues to add chapters to his already illustrious career.
      </p>
    </div>
    <Footer />

  </>
);

const BumrahHatTrick = () => (
  <>
    <Heder />
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-6 text-center">Bumrah's Hat-trick!</h1>
      <img
        src="images/bm.jpg"
        alt="Bumrah Hat-trick"
        className="w-full h-auto mb-6 rounded-lg shadow-lg"
      />
      <p className="text-lg text-gray-700 leading-relaxed">
        Jasprit Bumrah made history by taking a spectacular hat-trick in a
        high-stakes match against Australia in the ongoing ODI series. His fiery
        spell left the Australian batsmen stunned, as his deadly yorkers and
        pinpoint accuracy proved too much to handle. The delivery that sealed the
        hat-trick was an unplayable in-swinging yorker, uprooting the middle stump
        and sending the crowd into a frenzy.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        The hat-trick, which came in the 39th over of Australia's innings, saw
        Bumrah dismiss Alex Carey, Mitchell Starc, and Pat Cummins in consecutive
        deliveries. This remarkable feat made him only the third Indian bowler to
        achieve a hat-trick in ODI cricket, joining the elite company of Kapil Dev
        and Kuldeep Yadav.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        The moment was a turning point in the match, as Australia, who seemed on
        track to chase down a challenging target of 310, collapsed under pressure.
        Bumrah’s spell of 6 for 33 was instrumental in India securing a crucial
        victory by 45 runs, strengthening their lead in the series.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        Speaking at the post-match presentation, Bumrah said, “It’s a dream come
        true to achieve a hat-trick in international cricket. I’ve worked hard on
        my variations, and it’s great to see the results on such a big stage. The
        crowd’s support was incredible and pushed me to give my best.”
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        The cricketing world was quick to acknowledge Bumrah’s brilliance. Former
        cricketers and analysts heaped praise on him, with Sachin Tendulkar
        tweeting, "Bumrah is a national treasure! What a phenomenal spell of fast
        bowling." Glenn McGrath, a legend of Australian cricket, also
        congratulated Bumrah, calling him “one of the best fast bowlers in modern
        cricket.”
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        The victory also highlighted the strength of India’s bowling attack, with
        Bumrah leading the charge. His ability to deliver under pressure and his
        mastery of both pace and precision make him a vital asset for the team.
        Fans across the country celebrated the historic moment, with social media
        flooded with messages of admiration and pride.
      </p>
      <p className="text-lg text-gray-700 mt-4 leading-relaxed">
        As India gears up for the next match in the series, Bumrah’s sensational
        hat-trick will be remembered as one of the defining moments of his career.
        With performances like these, he continues to cement his legacy as one of
        the greatest fast bowlers in the history of Indian cricket.
      </p>
    </div>
    <Footer />
  </>
);



const Header = () => {
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/News" element={<News />} />
        <Route path="/Players" element={<Teams />} />
        <Route path="/Teams" element={<Players />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/" element={<CricketNewsSection />} />
        <Route path="/world-cup-win" element={<WorldCupWin />} />
        <Route path="/kohli-century" element={<KohliCentury />} />
        <Route path="/bumrah-hat-trick" element={<BumrahHatTrick />} />
      </Routes>



    </BrowserRouter>
  );
};

export default Header;
